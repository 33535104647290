import { useState, useLayoutEffect } from 'react'

// 割引バナー切り替えの日時
export const nextDiscountStartDate = new Date('2024/12/28 19:00:00')
// キャンペーン切り替えの日時
export const nextCampaignStartDate = new Date('2025/2/21 00:00:00')

export type RemainingTime = {
  milliSeconds: number
  days: number
  hours: number
  minutes: number
  seconds: number
}

const calculateRemainingTime = (targetDate: Date): RemainingTime => {
  const milliSeconds = Math.floor(
    (targetDate.getTime() - new Date().getTime()) / 1000
  )
  const days = Math.floor(milliSeconds / (60 * 60 * 24))
  const hours = Math.floor((milliSeconds / (60 * 60)) % 24)
  const minutes = Math.floor((milliSeconds / 60) % 60)
  const seconds = milliSeconds % 60
  return { milliSeconds, days, hours, minutes, seconds }
}

export const remainingTimeForCountdown = (): RemainingTime =>
  calculateRemainingTime(nextCampaignStartDate)

export const remainingTimeForDiscountCountdown = (): RemainingTime =>
  calculateRemainingTime(nextDiscountStartDate)

export const useCampaignAndCountdownStatus = () => {
  const [isNextCampaignStarted, setIsNextCampaignStarted] = useState(false)
  const [isCountdownActive, setIsCountdownActive] = useState(false)
  const [isNextDiscountStarted, setIsNextDiscountStarted] = useState(false)

  useLayoutEffect(() => {
    const updateCampaignAndCountdownStatus = () => {
      const timeRemaining = remainingTimeForCountdown()
      setIsNextCampaignStarted(timeRemaining.milliSeconds <= 0)
      setIsCountdownActive(timeRemaining.milliSeconds > 0)

      const timeRemainingForDiscount = remainingTimeForDiscountCountdown()
      setIsNextDiscountStarted(timeRemainingForDiscount.milliSeconds <= 0)
    }

    updateCampaignAndCountdownStatus()

    const countdownInterval = setInterval(
      updateCampaignAndCountdownStatus,
      1000
    )

    return () => clearInterval(countdownInterval)
  }, [])

  return { isNextCampaignStarted, isCountdownActive, isNextDiscountStarted }
}
